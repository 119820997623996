<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Payback Simples e Descontado</h2>
            <div class="form-todo form-group">
              <label for="taxa" class="mt-2 mb-0 d-flex justify-content-start"
                >Taxa de Juros (%):</label
              >
              <input
                type="number"
                step="any"
                v-model="taxa"
                name="taxa"
                class="form-control"
                placeholder="Ex.: 10 ou 10,00 ou 10.00"
              />

              <label
                for="fluxoDeCaixa"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Fluxo de Caixa:</label
              >
              <div
                class
                v-for="(item, index) in fluxoDeCaixa"
                :key="index"
                style="max-width: 400px"
              >
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  v-model="fluxoDeCaixa[index]"
                  placeholder="Ex.: 1000 ou 1000,00 ou 1000.00"
                />
              </div>
              <center>
                <button
                  v-on:click="addField"
                  type="submit"
                  class="btn btn-secondary mt-3"
                  style="width: 100px"
                >
                  +</button
                >&nbsp;
                <button
                  v-on:click="removeField"
                  type="submit"
                  class="btn btn-secondary mt-3"
                  style="width: 100px"
                >
                  -
                </button>
              </center>
              <br />

              <a
                href="#"
                v-on:click="calcular(taxa, fluxoDeCaixa, 3)"
                class="btn btn-warning"
                style="width: 300px"
                >Calcular</a
              >
            </div>
            <div>
              <h5>
                Payback Simples:
                {{ resultadoS.toFixed(3).toString().replace(".", ",") }} ({{Math.ceil(resultadoS)}})
              </h5>
              <h5>
                Payback descontado:
                {{ resultadoD.toFixed(3).toString().replace(".", ",") }} ({{Math.ceil(resultadoD)}})
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";

export default {
  name: "Payback",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Payback')
  },
  data() {
    return {
      page_id: "PAYBACK",
      taxa: Number,
      fluxoDeCaixa: [Number],
      resultadoS: 0,
      resultadoD: 0,
      result: 0,
      count: 0,
    };
  },
  methods: {
    calcular(taxa, fluxoDeCaixa, casasDecimais) {

      // deixei a feature de casas decimais aqui para se no futuro 
      // quisermos que o usuário escolha. Por enquanto está hardcoded
      // em 3 casas decimais lá em cima na chamada da função

      this.resultadoD = this.paybackDescontado(taxa, fluxoDeCaixa, casasDecimais);
      this.resultadoS = this.paybackSimples(fluxoDeCaixa, casasDecimais);
    },

    paybackSimples(fluxoDeCaixa, casasDecimais) {
      var i, resultAno = 0, aux = 0, resultado = -1
      for (i in fluxoDeCaixa) {
        var fluxo = parseFloat(fluxoDeCaixa[i])
        aux = resultAno;
        resultAno += fluxo
        if (resultAno > 0) {
          resultado = i - 1 - aux / (resultAno - aux)
          break
        }
      }

      if (resultAno > 0 && resultado == -1){
        resultado = i + 1
      } 

      resultado = Math.ceil(resultado * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais)

      return resultado;
    },
   
    paybackDescontado(taxa, fluxoDeCaixa, casasDecimais) {
      taxa = taxa/100
      var i, resultAno = 0, aux = 0, resultado = -1
      for (i in fluxoDeCaixa) {
        var fluxo = parseFloat(fluxoDeCaixa[i])
        aux = resultAno;
        resultAno += fluxo / Math.pow(1 + taxa, i)
        if (resultAno > 0) {
          resultado = i - 1 - aux / (resultAno - aux)
          break
        }
      }

      resultAno = resultAno / (1 + taxa)

      if (resultAno > 0 && resultado == -1){
        resultado = i + 1
      } 

      resultado = Math.ceil(resultado * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais)

      return resultado;
    },

    addField() {
      if (this.fluxoDeCaixa.length < 121) this.fluxoDeCaixa.push("");
    },
    removeField() {
      if (this.fluxoDeCaixa.length > 1) this.fluxoDeCaixa.pop();
    },
  },
};
</script>