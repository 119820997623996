<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Erro Amostral Média </h2>
            <div class="form-todo form-group">
              <label
                for="grauConfianca"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Grau de Confiança:</label
              >
              <select
                v-model="grauConfianca"
                name="grauConfianca"
                class="form-control"
              >
                <option value="2.58">99%</option>
                <option value="1.96">95%</option>
                <option value="1.64">90%</option>
              </select>

              <label
                for="tamanhoamostra"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Tamanho da amostra:</label
              >
              <input
                type="text"
                v-model="tamanhoamostra"
                name="tamanhoamostra"
                class="form-control"
              />

              <label
                for="desviopadrao"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Desvio-padrão:</label>
              <input
                type="text"
                v-model="desviopadrao"
                name="desviopadrao"
                class="form-control"
              />
              <a
                href="#"
                v-on:click="ErroAmostral"
                class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
               <h5 style="text-color:white" v-if="resultado">
                Erro para a Média: {{ parseFloat(resultado).toFixed(2).toString().replace(".", ",") }} 
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";
export default {
  name: "ErroAmostral",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Erro Amostral Média')
  },
  data() {
    return {
      desviopadrao: 0,
      grauConfianca: 0,
      tamanhoamostra: 0,
      resultado: 0,
    };
  },
  methods: {
    ErroAmostral() {
      this.resultado = this.grauConfianca*this.desviopadrao/ Math.pow( this.tamanhoamostra , 0.5);
      return this.resultado;
    },
  },
};
</script>