<template>
  <div id="app">
    <!-- NAVBAR - INICIO -->
    <div>
      <b-navbar
        fixed="top"
        class="shadow-lg p-3 mb-5 bg-white"
        toggleable="lg"
        type="light"
        variant="white"
      >
        <b-navbar-brand router-link to="/" class="m-0 p-0">
          <img src="../assets/logo.png" height="50" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown right>
            <template v-slot:button-content>Viabilidade de Projetos</template>
               <b-dropdown-item router-link to="roi"
                >Retorno Sobre Investimentos</b-dropdown-item >
              <b-dropdown-item router-link to="ponto-de-equilibrio"
                >Ponto de Equilíbrio</b-dropdown-item >
             <b-dropdown-item router-link to="payback"
                >Payback Simples e Descontado</b-dropdown-item >
              <b-dropdown-item router-link to="vpl-tir"
                >VPL e TIR</b-dropdown-item >
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right>
              <template v-slot:button-content> Juros Simples</template>
              <b-dropdown-item router-link to="taxa-nominal"
                >Taxa Nominal</b-dropdown-item
              >
               <b-dropdown-item router-link to="valor-futuro-simples"
              >  Valor Futuro</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right>
            <template v-slot:button-content>Juros Compostos</template>
              <b-dropdown-item router-link to="taxa-equivalente"
                >Taxa Equivalente</b-dropdown-item
              >
              <b-dropdown-item router-link to="valor-futuro-composto"
              >  Valor Futuro</b-dropdown-item>
          
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right>
              <template v-slot:button-content>Estatística</template>
              <b-dropdown-item router-link to="tamanho-da-amostra"
                >Tamanho da Amostra</b-dropdown-item
              >
                <b-dropdown-item router-link to="erro-proporcao"
                >Erro Amostral - Proporção</b-dropdown-item
              >
                <b-dropdown-item router-link to="erro-media"
                >Erro Amostral - Média</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item router-link to="blog">Blog</b-nav-item>
            <div v-if="user.loggedIn">
              <b-nav-item @click.prevent="signOut">Sair</b-nav-item>
            </div>
            <div v-else>
              <b-nav-item v-b-modal.modal-center variant="warning mb-3"
                >Login</b-nav-item
              >
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- NAVBAR - FIM -->

    <b-modal
      id="modal-center"
      title="Efetuar login/cadastro"
      hide-footer
      variant="m-0 p-0"
    >
      <Login />
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import Login from "./Login";

document.title = "Vem Calcular!";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  components: {
    Login,
  },
  name: "App",
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // this.$router.replace({
          //   name: "main",
          // });
        });
    },
  },
};
</script>
