<template>
  <div id="app">
    <NavigationBar />

    <!-- INÍCIO SEÇÃO 1 -->
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-img">
          <img src="../assets/intro-img.png" alt class="img-fluid" />
        </div>

        <div class="intro-info">
          <h3 style="color:white">
            Oferecemos soluções, dicas e ferramentas
            <br />para os seus cálculos.
          </h3>
          <div>
            <a href="#about" class="btn-get-started scrollto">Quero saber mais!</a>
            <router-link to="blog" class="btn-services">Ir para o Blog</router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- FIM SEÇÃO 1 -->
    <!-- INICIO SEÇÃO 2 -->
    <section id="about">
      <div class="container">
        <div class="row about-extra">
          <div class="col-lg-6 wow fadeInUp">
            <img src="../assets/intro-crescimento.png" class="img-fluid" alt />
          </div>
          <div class="col-lg-6 wow fadeInUp">
            <h4>Será que é tão difícil assim atingir a tão sonhada independência financeira?</h4>
            <p>Depende. O que você fez hoje para tentar alcançar as suas metas? Você age em seu dia a dia sendo inteligente financeiramente?</p>
            <p>Aqui no VEM CALCULAR! você encontra dicas em nosso Blog e nos nossos vídeos, além de ferramentas diversas para cálculos rápidos</p>
          </div>
        </div>
        <div class="row about-extra">
          <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
            <img src="../assets/intro-chave-sucesso.png" class="img-fluid" alt />
          </div>
          <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
            <h4>A chave do sucesso financeiro pode já estar em suas mãos...</h4>
            <p>... mas de nada adianta se não souber usá-la. Que tal estudarmos juntos sobre:</p>
            <ul>
              <li>Técnicas de planejamento financeiro</li>
              <li>Modalidades de investimento</li>
              <li>Mercado financeiro</li>
            </ul>
            <p>Ficando por dentro disso e muito mais, você estará melhor preparado(a) para o sucesso!</p>
          </div>
        </div>
      </div>
    </section>
    <!-- FIM SEÇÃO 2 -->
    <!-- INICIO RODAPÉ -->
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 footer-info">
              <img src="../assets/logo-rodape.png" height="70" />
              <p>Acompanhe também as nossas redes sociais:</p>
              <div class="social-links d-flex justify-content-center">
                <a href="#" class="twitter">
                  <i class="fa fa-twitter"></i>
                </a>
                <a href="#" class="facebook">
                  <i class="fa fa-facebook"></i>
                </a>
                <a href="#" class="instagram">
                  <i class="fa fa-instagram"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 footer-newsletter">
              <h4>Newsletter</h4>
              <p>Para receber as novidades automaticamente e estar sempre por dentro, assine nossa newsletter.</p>
              <form action method="post">
                <input type="email" name="email" />
                <input type="submit" value="Eu quero!" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright"></div>
        <div class="credits">www.vemcalcular.com.br</div>
      </div>
    </footer>
    <!-- FIM RODAPÉ -->
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";

export default {
  name: "App",
  components: {
    NavigationBar,
  },
};
</script>

