import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import { router } from './routes'
import firebase from "firebase/app"
require('firebase/firestore')
require('firebase/auth')
import { firestorePlugin } from 'vuefire'
import store from './store'

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: { 
    "image": true,
    "table": true,
    "code": true,
    "separator": true,
    "removeFormat": true,
    "orderedList": true,
    "unorderedList": true,
  },
});

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-PZTNTCKRQX" }
});



Vue.use(BootstrapVue, firestorePlugin);
Vue.config.productionTip = false

const configOptions = {
  apiKey: "AIzaSyAaHcs8rIjNDjJKxtBBnRxl6Z9-8Q1239k",
  authDomain: "vem-calcular.firebaseapp.com",
  databaseURL: "https://vem-calcular.firebaseio.com",
  projectId: "vem-calcular",
  storageBucket: "vem-calcular.appspot.com",
  messagingSenderId: "610675389550",
  appId: "1:610675389550:web:fdd388d2a64a66670ae27f",
  measurementId: "G-WEQXC25PRJ"
}

export const db = firebase.initializeApp(configOptions).firestore()

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
