<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>VPL e TIR</h2>
            <div class="form-todo form-group">
              <label for="taxa" class="mt-2 mb-0 d-flex justify-content-start"
                >Taxa de Juros (%):</label
              >
              <input
                type="number"
                step="any"
                v-model="taxa"
                name="taxa"
                class="form-control"
                placeholder="Ex.: 10 ou 10,00 ou 10.00"
              />

              <label
                for="fluxoDeCaixa"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Fluxo de Caixa:</label
              >
              <div
                class
                v-for="(item, index) in fluxoDeCaixa"
                :key="index"
                style="max-width: 400px"
              >
                <input
                  type="number"
                  step="any"
                  class="form-control"
                  v-model="fluxoDeCaixa[index]"
                  placeholder="Ex.: 1000 ou 1000,00 ou 1000.00"
                />
              </div>
              <center>
                <button
                  v-on:click="addField"
                  type="submit"
                  class="btn btn-secondary mt-3"
                  style="width: 100px"
                >
                  +</button
                >&nbsp;
                <button
                  v-on:click="removeField"
                  type="submit"
                  class="btn btn-secondary mt-3"
                  style="width: 100px"
                >
                  -
                </button>
              </center>
              <br />

              <a
                href="#"
                v-on:click="calcular(taxa, fluxoDeCaixa)"
                class="btn btn-warning"
                style="width: 300px"
                >Calcular</a
              >
            </div>
            <div>
              <h5>
                VPL: R$
                {{ resultadoVPL.toFixed(2).toString().replace(".", ",") }}
              </h5>
              <h5>
                TIR: {{ resultadoTIR.toFixed(3).toString().replace(".", ",") }}%
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";

export default {
  name: "ValorPresenteLiquido",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Valor Presente Liquido')
  },
  data() {
    return {
      page_id: "VPLeTIR",
      taxa: Number,
      fluxoDeCaixa: [Number],
      resultadoVPL: 0,
      resultadoTIR: 0,
    };
  },
  methods: {
    calcular(taxa, fluxoDeCaixa) {
      this.resultadoVPL = this.vpl(taxa, fluxoDeCaixa);
      this.resultadoTIR = this.tir(fluxoDeCaixa);
    },
    vpl(taxa, fluxoDeCaixa) {
      var result = parseFloat(fluxoDeCaixa[0]);
      for (var i = 1; i < fluxoDeCaixa.length; i++) {
        result +=
          parseFloat(fluxoDeCaixa[i]) / Math.pow(1 + parseFloat(taxa / 100), i);
      }
      return result;
    },
    tir(fluxoDeCaixa) {
      var juros_inicial = -1.0;
      var juros_medio = 0.0;
      var juros_final = 1.0;
      var vpl_inicial = 0.0;
      var vpl_final = 0.0;
      //var vf = 0.0;
      var erro = 1e-5;

      for (var i = 0; i < 100; i++) {
        vpl_inicial = this.vpl(juros_inicial, fluxoDeCaixa);
        vpl_final = this.vpl(juros_final, fluxoDeCaixa);
        if (this.sinal(vpl_inicial) != this.sinal(vpl_final)) break;
        juros_inicial -= 1.0;
        juros_final += 1.0;
      }
      var count = 0;
      for (;;) {
        // Busca por Bisseção
        juros_medio = (juros_inicial + juros_final) / 2.0;
        var vpl_medio = this.vpl(juros_medio, fluxoDeCaixa);

        if (Math.abs(vpl_medio) <= erro) {
          // Resultado foi encontrado
          return juros_medio;
        }
        if (this.sinal(vpl_inicial) == this.sinal(vpl_medio)) {
          juros_inicial = juros_medio;
          vpl_inicial = this.vpl(juros_medio, fluxoDeCaixa);
        } else {
          juros_final = juros_medio;
          vpl_final = this.vpl(juros_medio, fluxoDeCaixa);
        }
        if (++count > 10000) throw "looping inválido";
      }
    },

    sinal(x) {
      return x < 0.0 ? -1 : 1;
    },

    addField() {
      if (this.fluxoDeCaixa.length < 121) this.fluxoDeCaixa.push("");
    },
    removeField() {
      if (this.fluxoDeCaixa.length > 1) this.fluxoDeCaixa.pop();
    },
  },
};
</script>