<template>
  <div class="pt-5 mt-5 mb-5 pb-5" id="intro">
    <NavigationBar />

    <section id="intro" class="clearfix m-0 p-0">



      <h1 style="color: white;" class="mx-2">Blog</h1>
        <div class="col-lg-12">
          <b-pagination
            v-if="posts.length>perPage"
            class="d-flex justify-content-center"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="cardsPaginados"
          ></b-pagination>

          <div
            id="cardsPaginados"
            :posts="posts"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <div class="card my-5 p-0" v-for="post in posts" :key="post.id">
              <div class="card-header">
                <p class="m-0 p-0">
                  <b>{{post.titulo}}</b>
                </p>
                <p class="m-0 p-0" style="color:gray">{{post.categoria}}</p>
              </div>
              <div class="card-body p-0 m-0">

              <p class="text-justify" style="padding: 20px" v-html="post.texto"></p>

                <div v-if="post.codigoYouTube" class="d-flex justify-content-center">
                  <youtube
                    :video-id="post.codigoYouTube"
                    :fitParent="true"
                    :resize="true"
                    :height="300"
                    ref="youtube"
                  ></youtube>
                </div>
              </div>
              <div class="card-footer">
                <p
                  class="m-1 p-1"
                  style="color: gray; font-size: 10px; text-align: center;"
                >Criado por {{post.autor}} em {{post.data.toDate().toLocaleString()}}</p>
                <p
                  v-if="post.editor"
                  class="m-1 p-1"
                  style="color: gray; font-size: 10px; text-align: center;"
                >Editado por {{post.editor}} em {{post.dataEdicao.toDate().toLocaleString()}}</p>
              </div>
            </div>
          </div>

          <b-pagination
            v-if="posts.length>perPage"
            class="d-flex justify-content-center"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="cardsPaginados"
          ></b-pagination>
        </div>

    </section>
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import { db } from "../main";
import { mapGetters } from "vuex";
import { Youtube } from "vue-youtube";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.posts.length;
    },
  },
  components: {
    NavigationBar,
    Youtube,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      posts: [],
      categoria: null,
      categorias: [],
      novaCategoria: "",
      postEmEdicao: null,
    };
  },
  async mounted() {
    
    this.$gtag.event('Blog')

    try {
      await this.reloadCategorias();
    } catch (err) {
      console.log(err);
    }
    try {
      await this.loadPosts();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    loadPosts() {

      if(!this.$route.query.id){

        db.collection("blogPost")
      .where('publicar', '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.posts.push({
              id: doc.id,
              titulo: doc.data().titulo,
              categoria: doc.data().categoria,
              texto: doc.data().texto,
              codigoYouTube: doc.data().codigoYouTube,
              autor: doc.data().autor,
              editor: doc.data().editor,
              dataEdicao: doc.data().dataEdicao,
              data: doc.data().data,
            });
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });

      } else {

        
        ////gehT201LV8REL2wCCyDG


        db.collection("blogPost")
        .doc(this.$route.query.id)
        .get()
        .then((querySnapshot) => {
            this.posts.push(querySnapshot.data())
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });
      }

      
    },
  },
};
</script>