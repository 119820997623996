<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Ponto de Equilíbrio  </h2>
            <div class="form-todo form-group">

              <label
                for="fixo"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Gastos Fixos:</label
              >
              <input
                type="text"
                v-model="fixo"
                name="fixo"
                class="form-control"
              />
             <label
                for="variaveis"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Gastos variáveis:</label
              >
              <input
                type="text"
                v-model="variaveis"
                name="variaveis"
                class="form-control"
              />
              <label
                for="preco"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Preço:</label>
              <input
                type="text"
                v-model="preco"
                name="preco"
                class="form-control"
              />
              <a
                href="#"
                v-on:click="PontodeEquilibrio"
                class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
               <h5 style="text-color:white" v-if="resultado">
               Ponto de Equilíbrio (quantidade): {{ parseFloat(resultado).toFixed(2).toString().replace(".", ",") }} 
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";
export default {
  name: "PontodeEquilibrio",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Ponto de Equilíbrio')
  },
  data() {
    return {
      fixo: 0,
      variaveis: 0,
      preco: 0,
      resultado: 0,
    };
  },
  methods: {
   PontodeEquilibrio() {
      this.resultado = this.fixo/ (this.preco - this.variavel)
      return this.resultado;
    },
  },
};
</script>