<template>
  <div v-if="!user.loggedIn">
    <div v-if="!form.formCadastrar">
      <div class="form-group row justify-content-center m-0 p-0">
        <p style="font-size: 18px">
          <b>Selecione a sua rede social para efetuar o login:</b>
        </p>
      </div>
      <div class="form-group row justify-content-center m-0 p-0">
        <a href="#" @click="socialLogin(3)" class="icon-button twitter"
          ><i class="fa fa-twitter icon-twitter"></i><span></span
        ></a>
        <a href="#" @click="socialLogin(2)" class="icon-button facebook"
          ><i class="fa fa-facebook icon-facebook"></i><span></span
        ></a>
        <a href="#" @click="socialLogin(1)" class="icon-button google-plus"
          ><i class="fa fa-google icon-google-plus"></i><span></span
        ></a>
      </div>
      <div class="form-group row justify-content-center">
        <p class="mt-3 mb-0 mx-3" style="font-size: 14px">
          Não possui rede social? Efetue login com e-mail e senha.
        </p>
      </div>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
      <div class="form-group row justify-content-center">
        <form action="#" @submit.prevent="login">
          <input
            id="email"
            type="email"
            class="form-control m-2"
            name="email"
            value
            required
            v-model="form.email"
            placeholder="E-mail"
          />

          <input
            id="password"
            type="password"
            class="form-control m-2"
            name="password"
            required
            v-model="form.password"
            placeholder="Senha"
          />

          <p class="mb-0">
            Esqueceu a senha?
            <a href="#" @click="redefinirSenha(form.email)"
              >Redefinir senha agora!</a
            >
            <br />
            <br />
            <button type="submit" class="btn btn-warning form-control my-0">
              Login
            </button>
          </p>
        </form>
        <br />
        Ainda não possui cadastro?
        <button
          @click="form.formCadastrar = true"
          class="btn btn-success form-control mx-5"
        >
          Cadastre-se já!
        </button>
      </div>
    </div>
    <div v-else>
      <div class="form-group row justify-content-center m-0 p-0">
        <p style="font-size: 18px">
          <b>Cadastrar-se através da sua rede social preferida:</b>
        </p>
      </div>
      <div class="form-group row justify-content-center m-0 p-0">
        <a href="#" @click="socialLogin(3)" class="icon-button twitter"
          ><i class="fa fa-twitter icon-twitter"></i><span></span
        ></a>
        <a href="#" @click="socialLogin(2)" class="icon-button facebook"
          ><i class="fa fa-facebook icon-facebook"></i><span></span
        ></a>
        <a href="#" @click="socialLogin(1)" class="icon-button google-plus"
          ><i class="fa fa-google icon-google-plus"></i><span></span
        ></a>
      </div>
      <div class="form-group row justify-content-center">
        <p class="mt-3 mb-0 mx-3" style="font-size: 14px">
          Não possui rede social? Cadastre-se com e-mail e senha.
        </p>
      </div>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
      <form action="#" @submit.prevent="cadastrar">
        <div class="form-group row justify-content-center">
          <input
            id="name"
            type="name"
            class="form-control m-2"
            name="name"
            value
            required
            v-model="form.name"
            placeholder="Nome completo"
          />

          <input
            id="email"
            type="email"
            class="form-control m-2"
            name="email"
            value
            required
            v-model="form.email"
            placeholder="E-mail"
          />

          <input
            id="password"
            type="password"
            class="form-control m-2"
            name="password"
            required
            v-model="form.password"
            placeholder="Senha"
          />

          <input
            id="passwordConfirm"
            type="password"
            class="form-control m-2"
            name="passwordConfirm"
            required
            v-model="form.passwordConfirm"
            placeholder="Confirme a sua senha"
          />

          <p class="mb-0">
            <label>
            <input
              type="checkbox"
              name="termos"
              style="width: 20px; height: 20px"
              required
            />
            &nbsp; Li e estou de acordo com os</label>
            <a href="#">&nbsp;<u>termos de uso</u>.</a>

          </p>

          <button type="submit" class="btn btn-warning form-control mx-5 my-0">
            Cadastrar
          </button>
          <br />
          Já é cadastrado?
          <button
            @click="form.formCadastrar = false"
            class="btn btn-success form-control mx-5"
          >
            Efetuar login!
          </button>
        </div>
      </form>
    </div>
  </div>
  <div v-else>Login efetuado com sucesso!</div>
</template>
<script>
import firebase from "firebase";
import { mapGetters } from "vuex";

export default {
  name: "login",
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
        displayName: "",
        formCadastrar: false,
      },
      error: null,
    };
  },
  methods: {

    login() {
      
      this.$gtag.event('Login')

      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .catch((err) => {
          this.error = err.message;
          alert("Ops, ocorreu um erro: " + this.error)
        });
    },

    cadastrar() {

      this.$gtag.event('Cadastro')

      if (this.form.passwordConfirm == this.form.password) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.form.email, this.form.password)
          .then((data) => {
            data.user
              .updateProfile({
                displayName: this.form.name,
                loggedIn: true,
              })
              .then(() => {});
          })
          .catch((err) => {
            this.error = err.message;
            alert(this.error)
          });
      } else {
        alert("As senhas digitadas não conferem, por favor tente novamente.");
      }
    },
    redefinirSenha(email) {

      this.$gtag.event('Redefinição de senha')

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
          alert(
            "Sua senha foi redefinida com sucesso.\n\nAcesse o seu email de cadastro (" +
              email +
              ") e siga as instruções."
          );
        })
        .catch(function () {
          alert("Preencha o campo E-MAIL para redefinir a sua senha.");
        });
    },
    socialLogin(redeSocial) {
      if (redeSocial == 1) {
        this.form.redeSocial = new firebase.auth.GoogleAuthProvider();
      } else if (redeSocial == 2) {
        this.form.redeSocial = new firebase.auth.FacebookAuthProvider();
      } else if (redeSocial == 3) {
        this.form.redeSocial = new firebase.auth.TwitterAuthProvider();
      }

      firebase
        .auth()
        .signInWithPopup(this.form.redeSocial)
        .then(() => {
          // this.$router.replace("/");
        })
        .catch((err) => {
          if (
            err ==
            "Error: An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address."
          ) {
            alert(
              "Oops, ocorreu um erro: você já se cadastrou neste site com outra rede social.\n\nSelecione a rede social apropriada para prosseguir."
            );
          } else {
            alert("Oops, ocorreu um erro: " + err);
          }
        });
    },
  },
};
</script>
<style scopped>
/* Wrapper */
.icon-button {
  background-color: white;
  border-radius: 3.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  height: 3.6rem;
  line-height: 3.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 3.6rem;
}

/* Circle */
.icon-button span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 0;
}
.icon-button:hover span {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 3.6rem;
  margin: -1.8rem;
}
.twitter span {
  background-color: #4099ff;
}
.facebook span {
  background-color: #3b5998;
}
.google-plus span {
  background-color: #db5a3c;
}

/* Icons */
.icon-button i {
  background: none;
  height: 3.6rem;
  left: 0;
  line-height: 3.6rem;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 3.6rem;
  z-index: 10;
}
.icon-button .icon-twitter {
  color: #4099ff;
}
.icon-button .icon-facebook {
  color: #3b5998;
}
.icon-button .icon-google-plus {
  color: #db5a3c;
}
.icon-button:hover .icon-twitter,
.icon-button:hover .icon-facebook,
.icon-button:hover .icon-google-plus {
  color: #fff;
}
</style>