<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Retorno Sobre Investimentos </h2>
            <div class="form-todo form-group">

              <label
                for="inicial"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Valor Inicialmente investido:</label
              >
              <input
                type="text"
                v-model="inicial"
                name="inicial"
                class="form-control"
              />
             <label
                for="adicionais"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Gastos durante o período: (deixar em branco caso não tenham ocorrido)</label
              >
              <input
                type="text"
                v-model="adicionais"
                name="adicionais"
                class="form-control"
              />
              <label
                for="final"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Valor Total Resgatado:</label>
              <input
                type="text"
                v-model="final"
                name="final"
                class="form-control"
              />
              <a
                href="#"
                v-on:click="Roi"
                class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
               <h5 style="text-color:white" v-if="resultado">
                ROI: {{ parseFloat(resultado).toFixed(2).toString().replace(".", ",") }} %
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";
export default {
  name: "Roi",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Retorno Sobre Investimentos')
  },
  data() {
    return {
      inicial: 0,
      final: 0,
      adicionais: 0,
      resultado: 0,
    };
  },
  methods: {
    Roi() {
      this.resultado = 100* (this.final - this.adicionais - this.inicial) / this.inicial;
      return this.resultado;
    },
  },
};
</script>