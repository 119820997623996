<template>
  <div v-if="user.loggedIn" class="pt-5 mt-5 mb-5 pb-5" id="intro">
    <NavigationBarDashboard />

    <div class="container">
      <h3 style="color: white">Gereciar Administradores</h3>
      <div class="row d-flex justify-content-center">
        <div class="col-md-8">
          <form action="#" @submit.prevent="adicionarNovoAdministrador()">
            <div class="input-group my-4">
              <input
                id="email"
                type="email"
                class="form-control"
                name="email"
                placeholder="Adicionar administrador"
                required
                autocomplete="off"
                v-model="email"
              />
              <div class="input-group-append">
                <button type="submit" class="btn btn-warning">Adicionar</button>
              </div>
            </div>
          </form>
          <div
            class="card d-flex p-2"
            v-for="administrador in administradores"
            :key="administrador.id"
          >
            <div class="mt-auto align-self-center mr-auto p-0">
              {{ administrador.email }}
            </div>
            <div class="mt-auto align-self-center ml-auto p-0">
              <button
                class="btn btn-sm btn-danger"
                @click="removerPoderes(administrador.id, administrador.email)"
              >
                Remover Poderes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import NavigationBarDashboard from "./NavigationBarDashboard.vue";
import { db } from "../main";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.administradores.length;
    },
  },
  components: {
    NavigationBarDashboard,
  },
  data() {
    return {
      perPage: 30,
      currentPage: 1,
      administradores: [],
      email: "",
      emailJaCadastrado: null,
    };
  },
  async mounted() {
    try {
      await this.loadAdministradores();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    loadAdministradores() {
      db.collection("administrador")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.administradores.push({
              id: doc.id,
              email: doc.data().email,
            });
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });
    },

    removerPoderes(id, email) {
      if (
        confirm(
          "Você tem certeza que deseja remover os poderes do administrador selecionado?\n\n" +
            email
        )
      ) {
        db.collection("administrador")
          .doc(id)
          .delete()
          .then(() => {
            this.administradores = [];
            this.loadAdministradores();
          })
          .catch((error) => {
            alert("Ocorreu um erro, tente novamente. (" + error + ")");
          });
      }
    },
    adicionarNovoAdministrador() {
      db.collection("administrador")
        .where("email", "==", this.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.emailJaCadastrado = doc.data().email;
            console.log(this.emailJaCadastrado);
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        })
        .then(() => {
          if (this.emailJaCadastrado != this.email) {
            db.collection("administrador")
              .add({
                email: this.email,
                data: new Date(),
              })
              .catch((err) => {
                this.error = err.message;
              })
              .then(() => {
                alert("Administrador adicionado com sucesso!");
                this.emailJaCadastrado = null;
                this.email = "";
                this.administradores = [];
                this.loadAdministradores();
              });
          } else {
            alert("Este e-mail já se encontra cadastrado como administrador!");
            this.emailJaCadastrado = null;
          }
        });
    },
  },
};
</script>