<template>
  <div v-if="administradorLogado" class="pt-5 mt-5 mb-5 pb-5" id="intro">
    <NavigationBarDashboard />

    <div class="container">
      <h3 style="color: white;">Blog - Visualizar Posts</h3>
      <div class="row d-flex justify-content-center">
        <div class="col-md-8">
          <b-pagination
            class="d-flex justify-content-center"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

          <div id="my-table" :posts="posts" :per-page="perPage" :current-page="currentPage">
            <div class="card mb-4" v-for="post in posts" :key="post.id">
              <!-- INICIO MODO DE EDIÇÃO -->
              <div v-if="postEmEdicao == post.id">
                <form
                  action="#"
                  @submit.prevent="salvarAlteracoes(post.id, post.titulo, post.categoria, novaCategoria, post.texto, post.codigoYouTube, post.publicar)"
                >
                  <div class="card-header">Editar Post</div>

                  <div class="form-group row mt-3">
                    <label for="name" class="col-md-4 col-form-label text-md-right">Título</label>
                    <div class="col-md-6">
                      <input
                        id="titulo"
                        type="text"
                        class="form-control"
                        name="titulo"
                        required
                        v-model="post.titulo"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="name"
                      class="col-md-4 col-form-label text-md-right"
                    >Selecionar Categoria</label>
                    <div class="col-md-6">
                      <select
                        id="categoria"
                        type="text"
                        class="form-control"
                        name="categoria"
                        required
                        v-model="post.categoria"
                      >
                        <option :value="1">Cadastrar nova...</option>

                        <option
                          v-for="categoria in categorias"
                          :key="categoria.key"
                          :value="categoria.categoria"
                        >{{categoria.categoria}}</option>
                      </select>
                    </div>
                  </div>

                  <div v-if="post.categoria == 1" class="form-group row">
                    <label for="name" class="col-md-4 col-form-label text-md-right">Nova Categoria</label>
                    <div class="col-md-6">
                      <input
                        id="novaCategoria"
                        type="text"
                        class="form-control"
                        name="categoria"
                        required
                        v-model="novaCategoria"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="name" class="col-md-4 col-form-label text-md-right">Texto</label>
                    <div class="col-md-6">
                      <wysiwyg v-model="post.texto" />
                    </div>
                  </div>
                  <div class="form-group row mt-3">
                    <label for="name" class="col-md-4 col-form-label text-md-right">Código YouTube</label>
                    <div class="col-md-6">
                      <input
                        id="codigoYouTube"
                        type="text"
                        class="form-control"
                        name="codigoYouTube"
                        v-model="post.codigoYouTube"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                  <label for="publicar" class="col-md-4 col-form-label text-md-right">Publicar?</label>
                  <div class="col-md-6 justify-content-left">
                    <input
                      id="publicar"
                      type="checkbox"
                      class="form-control"
                      name="publicar"
                      v-model="post.publicar"
                    />
                  </div>
                </div>
                  <div class="card-footer">
                    <p
                      class="m-1 p-1"
                      style="color: gray; font-size: 10px; text-align: center;"
                    >Criado por {{post.autor}} em {{post.data.toDate().toLocaleString()}}</p>
                    <p
                      v-if="post.editor"
                      class="m-1 p-1"
                      style="color: gray; font-size: 10px; text-align: center;"
                    >Editado por {{post.editor}} em {{post.dataEdicao.toDate().toLocaleString()}}</p>
                    <p class="m-1 p-1" style="text-align: center;">
                      <button type="submit" class="btn btn-primary">Salvar Alterações</button>&nbsp;
                      <button
                        @click="habilitarEdicaoPost(null)"
                        class="btn btn-primary"
                      >Cancelar</button>
                    </p>
                  </div>
                </form>
              </div>
              <!-- FIM MODO DE EDIÇÃO -->
              <div v-else>
                <div class="card-header">
                  <p class="m-0 p-0">
                    Título:
                    <b>{{post.titulo}}</b>
                  </p>
                  <p class="m-0 p-0">
                    Categoria:
                    <b>{{post.categoria}}</b>
                  </p>
                  <p class="m-0 p-0">
                    Publicado: <b v-if="post.publicar == true" style="color: green;">Sim</b><b v-else style="color: red;">Não</b>
                  </p>
                </div>
                <div class="card-body">
                  <p class="text-justify" v-html="post.texto"></p>
                  <div v-if="post.codigoYouTube" class="d-flex justify-content-center">
                    <youtube
                      :video-id="post.codigoYouTube"
                      :fitParent="true"
                      :resize="true"
                      :height="200"
                      ref="youtube"
                    ></youtube>
                  </div>
                </div>
                <div class="card-footer">
                  <p
                    class="m-1 p-1"
                    style="color: gray; font-size: 10px; text-align: center;"
                  >Criado por {{post.autor}} em {{post.data.toDate().toLocaleString()}}</p>
                  <p
                    v-if="post.editor"
                    class="m-1 p-1"
                    style="color: gray; font-size: 10px; text-align: center;"
                  >Editado por {{post.editor}} em {{post.dataEdicao.toDate().toLocaleString()}}</p>
                  <p class="m-1 p-1" style="text-align: center;">
                    <button @click="habilitarEdicaoPost(post.id)" class="btn btn-primary">Editar</button>&nbsp;
                    <button @click="apagarPost(post.id)" class="btn btn-danger">Apagar</button>
                  </p>
                  <p class="text-muted" style="font-size: 10px"><a :href="'https://www.vemcalcular.com.br/blog?id=' + post.id">https://www.vemcalcular.com.br/blog?id={{post.id}}</a></p>
                </div>
              </div>
            </div>
          </div>

          <b-pagination
            class="d-flex justify-content-center"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import NavigationBarDashboard from "./NavigationBarDashboard.vue";
import { db } from "../main";
import { mapGetters } from "vuex";
import { Youtube } from "vue-youtube";
import "vue-wysiwyg/dist/vueWysiwyg.css";


export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.posts.length;
    },

    filteredOptions() {
      return [
        { 
          data: this.suggestions[0].data.filter(option => {
            return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          })
        }
      ];
    }


  },
  components: {
    NavigationBarDashboard,
    Youtube,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      posts: [],
      categoria: null,
      categorias: [],
      novaCategoria: "",
      postEmEdicao: null,
      administradorLogado: false,
    };
  },
  async mounted() {
    try {
      await this.loadAdmin();
    } catch (err) {
      console.log(err);
    }
    try {
      await this.reloadCategorias();
    } catch (err) {
      console.log(err);
    }
    try {
      await this.loadPosts();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    loadPosts() {
      db.collection("blogPost")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.posts.push({
              id: doc.id,
              titulo: doc.data().titulo,
              categoria: doc.data().categoria,
              texto: doc.data().texto,
              codigoYouTube: doc.data().codigoYouTube,
              autor: doc.data().autor,
              publicar: doc.data().publicar,
              editor: doc.data().editor,
              dataEdicao: doc.data().dataEdicao,
              data: doc.data().data,
            });
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });
    },

    reloadCategorias() {
      db.collection("categoria")
        .get()
        .then((querySnapshot) => {
          this.categorias = querySnapshot.docs.map((doc) => doc.data());
        });
    },

    habilitarEdicaoPost(id) {
      this.postEmEdicao = id;
    },

    salvarAlteracoes(
      id,
      titulo,
      categoria,
      novaCategoria,
      texto,
      codigoYouTube,
      publicar
    ) {
      if (categoria == 1) {
        categoria = novaCategoria;
        db.collection("categoria")
          .add({
            categoria: novaCategoria,
          })
          .catch((err) => {
            this.error = err.message;
            console.log(this.error);
          });
        this.loadPosts();
      }

      db.collection("blogPost")
        .doc(id)
        .update({
          titulo: titulo,
          categoria: categoria,
          texto: texto,
          codigoYouTube: codigoYouTube,
          publicar: publicar,
          editor: this.user.data.displayName,
          dataEdicao: new Date(),
        })
        .then(() => {
          alert("Post alterado com sucesso.");
          this.posts = [];
          this.loadPosts();
          this.postEmEdicao = null;
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });
    },

    apagarPost(id) {
      if (confirm("Você tem certeza que deseja apagar o post selecionado?")) {
        db.collection("blogPost")
          .doc(id)
          .delete()
          .then(() => {
            this.posts = [];
            this.loadPosts();
            this.postEmEdicao = null;
          })
          .catch((error) => {
            alert("Ocorreu um erro, tente novamente. (" + error + ")");
          });
      }
    },
    loadAdmin(){
      db.collection("administrador")
      .where('email', '==', this.user.data.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(() => {
            this.administradorLogado = true
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        })
    }
  },


    onSelected(item) {
      this.selected = item.item;
    },
    onInputChange(text) {
      // event fired when the input changes
      console.log(text)
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    }
};
</script>