<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Valor Futuro</h2>
            <div class="form-todo form-group">
              <label
                for="ValorPresente"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Valor Presente:</label
              >
              <input
                type="text"
                v-model="ValorPresente"
                name="ValorPresente"
                class="form-control"
              />

              <label
                for="Numerodeperiodos"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Número de Períodos:</label>
              <input
                type="text"
                v-model="NumerodePeriodos"
                name="NumerodePeriodos"
                class="form-control"
              />
             <label
                for="taxa"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Taxa de Juros (%):</label>
              <input
                type="text"
                v-model="taxa"
                name="taxa"
                class="form-control"
              />
              <a
                href="#"
                v-on:click="ValorFuturoCalc"
                class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
               <h5 style="text-color:white" v-if="resultado">
                Valor Futuro: R$ {{ parseFloat(resultado).toFixed(2).toString().replace(".", ",") }} 
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";
export default {
  name: "ValorFuturoCalc",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Valor Futuro Composto')
  },
  data() {
    return {
      ValorFuturo: 0,
      ValorPresente: 0,
      taxa: 0,
      NumerodePeriodos: 0,
      resultado: 0,
    };
  },
  methods: {
    ValorFuturoCalc() {
      this.resultado = this.ValorPresente* Math.pow( (1+ this.taxa/100) , this.NumerodePeriodos);
      return this.resultado;
    },
  },
};
</script>