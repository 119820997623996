<template>
  <div v-if="administradorLogado" id="intro">
    <NavigationBarDashboard />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">Adicionar Post</div>
            <div class="card-body">
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
              <div v-if="success" class="alert alert-success">
                {{ success }}
              </div>

              <form action="#" @submit.prevent="gravarPost()">
                <div class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    >Título</label
                  >
                  <div class="col-md-6">
                    <input
                      id="titulo"
                      type="text"
                      class="form-control"
                      name="titulo"
                      required
                      v-model="titulo"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    >Selecionar Categoria</label
                  >
                  <div class="col-md-6">
                    <select
                      id="categoria"
                      type="text"
                      class="form-control"
                      name="categoria"
                      required
                      v-model="categoria"
                    >
                      <option value="1">Cadastrar nova...</option>

                      <option
                        v-for="categoria in categorias"
                        :key="categoria.key"
                        :value="categoria.categoria"
                      >
                        {{ categoria.categoria }}
                      </option>
                    </select>
                  </div>
                </div>

                <div v-if="categoria == 1" class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    >Nova Categoria</label
                  >
                  <div class="col-md-6">
                    <input
                      id="novaCategoria"
                      type="text"
                      class="form-control"
                      name="categoria"
                      required
                      v-model="novaCategoria"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    >Texto</label
                  >
                  <div class="col-md-6">

                  <wysiwyg v-model="texto" />
             
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="name"
                    class="col-md-4 col-form-label text-md-right"
                    >Código YouTube</label
                  >
                  <div class="col-md-6">
                    <input
                      id="codigoYouTube"
                      type="text"
                      class="form-control"
                      name="codigoYouTube"
                      v-model="codigoYouTube"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="publicar"
                    class="col-md-4 col-form-label text-md-right"
                    >Publicar?</label
                  >
                  <div class="col-md-6 justify-content-left">
                    <input
                      id="publicar"
                      type="checkbox"
                      class="form-control"
                      name="publicar"
                      v-model="publicar"
                    />
                  </div>
                </div>

                <p style="color: gray; font-size: 10px; text-align: center">
                  Postado por {{ user.data.displayName }} em {{ data }}.
                </p>
                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-4 d-flex justify-content-end">
                    <button type="submit" class="btn btn-dark">
                      Cadastrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import NavigationBarDashboard from "./NavigationBarDashboard.vue";
import { db } from "../main";
import { mapGetters } from "vuex";
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  components: {
    NavigationBarDashboard,
  },
  data() {
    return {
      titulo: "",
      categoria: "",
      novaCategoria: "",
      texto: "",
      codigoYouTube: null,
      autor: "",
      publicar: false,
      data: new Date(),
      dataEdicao: null,
      categorias: [],
      error: null,
      success: null,
      administradorLogado: false,
    };
  },
  async mounted() {
    try {
      await this.reloadCategorias();
    } catch (err) {
      console.log(err);
    }
    try {
      await this.loadAdmin();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    reloadCategorias() {
      db.collection("categoria")
        .get()
        .then((querySnapshot) => {
          this.categorias = querySnapshot.docs.map((doc) => doc.data());
        });
    },
    gravarPost() {
      if (this.categoria == 1) {
        this.categoria = this.novaCategoria;
        db.collection("categoria")
          .add({
            categoria: this.novaCategoria,
          })
          .catch((err) => {
            this.error = err.message;
          });
      }

      db.collection("blogPost")
        .add({
          titulo: this.titulo,
          categoria: this.categoria,
          texto: this.texto,
          codigoYouTube: this.codigoYouTube,
          autor: this.user.data.displayName,
          publicar: this.publicar,
          editor: null,
          data: this.data,
          edit: true,
        })
        .catch((err) => {
          this.error = err.message;
        });

      this.reloadCategorias();
      this.success = "Post adicionado com sucesso!";
      this.titulo = "";
      this.texto = "";
      this.publicar = false;
      this.codigoYouTube = null;
      this.categoria = "";
    },
    loadAdmin() {
      db.collection("administrador")
        .where("email", "==", this.user.data.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(() => {
            this.administradorLogado = true;
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });
    },
  },
};
</script>