<template>
  <div class="container">
    <div class="card my-3">
      <div class="card-header">Seção de comentários</div>
      <div class="card-body">
        <!-- INICIO ADICIONAR COMENTARIO -->
        <div v-if="user.loggedIn">
          <form action="#" @submit.prevent="publicarComentario()">
            <div class="input-group my-4">
              <input
                id="comentario"
                type="text"
                class="form-control"
                name="comentario"
                placeholder="Enviar comentário"
                required
                autocomplete="off"
                v-model="comentario"
              />
              <div class="input-group-append">
                <button type="submit" class="btn btn-warning">Enviar</button>
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <b-button v-b-modal.modal-center variant="warning mb-3"
            >Quero comentar!</b-button
          >
        </div>

        <!-- INICIO COMENTARIOS -->
        <div v-if="comentarios.length > 0">
          <div
            class="card mb-4 p-2"
            v-for="comentario in comentarios"
            :key="comentario.id"
          >
            <b>{{ comentario.autor }}</b>
            <p class="m-0 p-0">{{ comentario.comentario }}</p>
            <p class="m-0 p-0" style="color: gray; font-size: 8px">
              {{ comentario.data.toDate().toLocaleString() }}
            </p>
          </div>
        </div>
        <div v-else>
          Esta seção ainda não possui nenhum comentário. Que tal ser o primeiro?
        </div>
        <!-- FIM COMENTARIOS -->
      </div>
    </div>
    <!-- FIM ADICIONAR COMENTARIO -->
  </div>
</template>

<script>
import { db } from "../main";
import { mapGetters } from "vuex";

export default {
  props: {
    paginaDonaDosComentarios: String,
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  data() {
    return {
      comentarios: [],
      comentario: null,
    };
  },
  async mounted() {
    try {
      await this.loadComentarios();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    loadComentarios() {
      db.collection("Comentarios" + this.paginaDonaDosComentarios)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.comentarios.push({
              id: doc.id,
              autor: doc.data().autor,
              comentario: doc.data().comentario,
              data: doc.data().data,
            });
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        });
    },
    publicarComentario() {
      db.collection("Comentarios" + this.paginaDonaDosComentarios)
        .add({
          autor: this.user.data.displayName,
          email: this.user.data.email,
          comentario: this.comentario,
          data: new Date(),
        })
        .catch((err) => {
          this.error = err.message;
        });

      this.loadComentarios();
      this.success = "Comentário enviado com sucesso!";
      this.comentario = "";
    },
  },
};
</script>