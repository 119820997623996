<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Taxa Nominal</h2>
            <div class="form-todo form-group">
              <label for="taxa" class="mt-2 mb-0 d-flex justify-content-start"
                >Taxa (%):</label
              >
              <input
                type="number"
                v-model="taxa"
                name="taxa"
                class="form-control"
              />
              <label
                for="PeriodoTaxa"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Período da Taxa:</label
              >
              <select
                v-model="periodoTaxa"
                name="periodoTaxa"
                class="form-control"
              >
                <option value="1">Dia</option>
                <option value="30">Mês</option>
                <option value="60">Bimestre</option>
                <option value="180">Semestre</option>
                <option value="360">Ano</option>
              </select>
              <label
                for="PeriodoCapitalização"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Período de Capitalização:</label
              >
              <select
                v-model="periodoCapitalizacao"
                name="periodoCapitalizacao"
                class="form-control"
              >
                <option value="1">Dia</option>
                <option value="30">Mês</option>
                <option value="60">Bimestre</option>
                <option value="180">Semestre</option>
                <option value="360">Ano</option>
              </select>
              <label
                for="PeriodoSaida"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Período de Saída:</label
              >
              <select
                v-model="periodoSaida"
                name="periodoSaida"
                class="form-control"
              >
                <option value="1">Dia</option>
                <option value="30">Mês</option>
                <option value="60">Bimestre</option>
                <option value="180">Semestre</option>
                <option value="360">Ano</option>
              </select>
              <a href="#" v-on:click="taxaNominal" class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
              <h5 v-if="resultadoNominal">
                Taxa Nominal:
                {{ resultadoNominal.toFixed(2).toString().replace(".", ",") }}%
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";

export default {
  name: "TaxaNominal",
  components: {
    NavigationBar,
    Comentarios,
  },

  mounted() {
    this.$gtag.event('Taxa Nominal')
  },

  data() {
    return {
      page_id: "TaxaNominal",
      taxa: 1,
      periodoTaxa: "",
      periodoCapitalizacao: "",
      periodoSaida: "",
      resultadoNominal: 0,
    };
  },
  methods: {
    taxaNominal() {
      let resultado =
        (this.taxa * this.periodoCapitalizacao) / this.periodoTaxa;
      this.resultadoNominal =
        (Math.pow(
          1 + resultado / 100,
          this.periodoSaida / this.periodoCapitalizacao
        ) -
          1) *
        100;
    },
  },
};
</script>