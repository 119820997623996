<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="intro-info d-flex justify-content-center">
          <div class="containter mx-2" style="max-width: 400px">
            <h2>Tamanho da Amostra</h2>
            <div class="form-todo form-group">
              <label
                for="populacao"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Populacão: (caso não possua deixe este valor) </label
              >
              <input
                type="number"
                v-model="populacao"
                name="populacao"
                class="form-control"
              />
              <label
                for="grauConfianca"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Grau de Confiança:</label
              >
              <select
                v-model="grauConfianca"
                name="grauConfianca"
                class="form-control"
              >
                <option value="6.6564">99%</option>
                <option value="3.8416">95%</option>
                <option value="2.7225">90%</option>
                <option value="2.0736">85%</option>
                <option value="1.6384">80%</option>
              </select>
              <label
                for="margemDeErro"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Margem de Erro (%):</label
              >
              <input
                type="number"
                v-model="margemDeErro"
                name="margemDeErro"
                class="form-control"
              />
                    <label
                for="proporcao"
                class="mt-2 mb-0 d-flex justify-content-start"
                >Proporção (%): (caso não conheça deixe este valor) </label
              >
              <input
                type="number"
                v-model="proporcao"
                name="proporcao"
                class="form-control"
              />
              <a
                href="#"
                v-on:click="TamanhoDaAmostra"
                class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
              <h5 v-if="resultado">
                Tamanho da Amostra: {{ Math.ceil(resultado) }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import Comentarios from "./Comentarios.vue";

export default {
  name: "TamanhoDaAmostra",
  components: {
    NavigationBar,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Tamanho da Amostra')
  },
  data() {
    return {
      populacao: 210000000,
      proporcao: 50,
      grauConfianca: 0,
      margemDeErro: 0,
      z: 0,
      resultado: 0,
    };
  },
  methods: {
    TamanhoDaAmostra() {
      let margemDeErroPorcentagem = Math.pow(this.margemDeErro / 100, 2);
      this.resultado =
        ((1 / margemDeErroPorcentagem) * this.grauConfianca * ( this.proporcao/100* (1.0 - this.proporcao/100 ))) /
        (1 +
          (1 / (margemDeErroPorcentagem * this.populacao)) *
            this.grauConfianca *
            ( this.proporcao/100 * ( 1.0 - this.proporcao/100 )));
      return this.resultado;
    },
  },
};
</script>