import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from './components/Main'
import TamanhoDaAmostra from './components/TamanhoDaAmostra'
import TaxaEquivalente from './components/TaxaEquivalente'
import TaxaNominal from './components/TaxaNominal'
import ValorPresenteLiquido from './components/ValorPresenteLiquido'
import Dashboard from './components/Dashboard'
import GerenciarAdministradores from './components/GerenciarAdministradores'
import BlogAdicionarPost from './components/BlogAdicionarPost'
import BlogVisualizarPosts from './components/BlogVisualizarPosts'
import Blog from './components/Blog'
import Login from './components/Login'
import ErroProporcao from './components/ErroAmostralproporcao'
import ErroMedia from './components/ErroAmostralmedia'
import ValorFuturoComposto from './components/ValorFuturoComposto'
import ValorFuturoSimples from './components/ValorFuturoSimples'
import PontodeEquilibrio from './components/PontodeEquilibrio'
import Payback from './components/Payback'
import Roi from './components/Roi'



import Admin from './components/Admin'

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  
  routes: [
    { path: '/', name: 'main', component: Main },
    { path: '/tamanho-da-amostra', name: 'tamanho-da-amostra', component: TamanhoDaAmostra },
    { path: '/taxa-equivalente', name: 'taxa-equivalente', component: TaxaEquivalente },
    { path: '/taxa-nominal', name: 'taxa-nominal', component: TaxaNominal },
    { path: '/vpl-tir', name: 'vpl-tir', component: ValorPresenteLiquido },
    { path: '/blog', name: 'blog', component: Blog },
    { path: '/dashboard', name: 'dashboard', component: Dashboard },
    { path: '/dashboard/gerenciar-administradores', name: 'gerenciar-administradores', component: GerenciarAdministradores },
    { path: '/dashboard/blog-adicionar-post', name: 'blog-adicionar-post', component: BlogAdicionarPost },
    { path: '/dashboard/blog-visualizar-posts', name: 'blog-visualizar-posts', component: BlogVisualizarPosts },
    { path: '/admin', name: 'admin', component: Admin },
    { path: '/erro-proporcao', name: 'erro-proporcao', component: ErroProporcao },
    { path: '/erro-media', name: 'erro-media', component: ErroMedia },
    { path: '/valor-futuro-composto', name: 'erro-media', component: ValorFuturoComposto },
    { path: '/valor-futuro-simples', name: 'erro-media', component: ValorFuturoSimples },
    { path: '/payback', name: 'erro-media', component: Payback },
    { path: '/ponto-de-equilibrio', name: 'erro-media', component: PontodeEquilibrio },
    { path: '/roi', name: 'erro-media', component: Roi },

    //temporario:
    { path: '/login', name: 'login', component: Login },
  ]
})
