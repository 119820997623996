<template>
  <div id="app">
    <!-- NAVBAR - INICIO -->
      <b-navbar
        fixed="top"
        class="shadow-lg p-2 mb-3 bg-white"
        toggleable="lg"
        type="light"
        variant="white"
      >
        <b-navbar-brand router-link to="/dashboard" class="m-0 p-0">
          <img src="../assets/logo-dashboard.png" height="50" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-navbar-nav>
            <b-nav-item>Adm.: {{user.data.displayName}}</b-nav-item>
          </b-navbar-nav>
            <b-nav-item-dropdown right>
              <template v-slot:button-content>Blog</template>
              <b-dropdown-item router-link to="/dashboard/blog-visualizar-posts">Visualizar Blog</b-dropdown-item>
              <b-dropdown-item router-link to="/dashboard/blog-adicionar-post">Adicionar Post</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right>
              <template v-slot:button-content>Registro</template>
              <b-dropdown-item router-link to="/dashboard/gerenciar-administradores">Gerenciar Administradores</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item router-link to="/">Site</b-nav-item>
            <b-nav-item @click.prevent="signOut">Sair</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    <!-- NAVBAR - FIM -->
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";

export default {
      computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "admin",
          });
        });
    },
  },
};
</script>