<template>
  <div id="app">
    <NavigationBar />
    <section id="intro" class="clearfix">
      <div class="container">
        <div class="card-deck">
          <div class="card p-5 bg-transparent border-0">
            <h2 style="color: white">Taxa Equivalente</h2>
            <div class="form-todo form-group">
              <label for="taxa" class="mt-2 mb-0 d-flex justify-content-start"
                >Taxa (%):</label
              >
              <input
                type="number"
                v-model="taxa"
                name="taxa"
                class="form-control"
              />
              <label for="taxa" class="mt-2 mb-0 d-flex justify-content-start"
                >Período de Entrada:</label
              >
              <select
                name="periodoEntrada"
                v-model="periodoEntrada"
                class="form-control"
              >
                <option value="1">Dia</option>
                <option value="30">Mês</option>
                <option value="60">Bimestre</option>
                <option value="180">Semestre</option>
                <option value="360">Ano</option>
              </select>
              <label for="taxa" class="mt-2 mb-0 d-flex justify-content-start"
                >Período de Saída:</label
              >
              <select
                name="periodoSaida"
                v-model="periodoSaida"
                class="form-control"
              >
                <option value="1">Dia</option>
                <option value="30">Mês</option>
                <option value="60">Bimestre</option>
                <option value="180">Semestre</option>
                <option value="360">Ano</option>
              </select>
              <a
                href="#"
                v-on:click="taxaEquivalente"
                class="btn btn-warning mt-3"
                >Calcular</a
              >
            </div>
            <div>
              <h5 v-if="resultado">
                Taxa Equivalente:
                {{ resultado.toFixed(2).toString().replace(".", ",") }}%
              </h5>
            </div>
          </div>

          <div
            class="card mycomponent bg-transparent border-0 d-flex justify-content-center"
          >
            <youtube
              :video-id="codigoYouTube"
              :fitParent="true"
              :resize="true"
              :width="400"
              ref="youtube"
            ></youtube>
          </div>
        </div>
      </div>
    </section>

    <Comentarios :paginaDonaDosComentarios="page_id" />
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import { Youtube } from "vue-youtube";
import Comentarios from "./Comentarios.vue";

export default {
  name: "TaxaEquivalente",
  components: {
    NavigationBar,
    Youtube,
    Comentarios,
  },
  mounted() {
    this.$gtag.event('Taxa Equivalente')
  },
  data() {
    return {
      page_id: "TaxaEquivalente",
      taxa: 1,
      periodoEntrada: "",
      periodoSaida: "",
      resultado: 0,
      codigoYouTube: "PEFMFJ9oW-k",
    };
  },
  methods: {
    taxaEquivalente() {
      this.resultado =
        (Math.pow(
          1 + this.taxa / 100,
          this.periodoSaida / this.periodoEntrada
        ) -
          1) *
        100;
    },
  },
};
</script>