<template>
  <div v-if="administradorLogado" id="intro">
    <NavigationBarDashboard />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">Seja bem vindo, {{ user.data.displayName }}.</div>
            <div
              class="card-body d-flex justify-content-center"
            >Selecione uma opção do menu acima para prosseguir.</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">Usuário deslogado</div>
            <div class="card-body">
              <a href="/#/admin" class="btn-get-started scrollto">Efetuar Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBarDashboard from "./NavigationBarDashboard.vue";
import { mapGetters } from "vuex";
import { db } from "../main";


export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    
  },
  async mounted() {
    try {
      await this.loadAdmin();
    } catch (err) {
      console.log(err);
    }

  },
  components: {
    NavigationBarDashboard,
  },
  data() {
    return {
    administradorLogado: false,
  }
  },
  methods: {
    loadAdmin(){
      db.collection("administrador")
      .where('email', '==', this.user.data.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(() => {
            this.administradorLogado = true
          });
        })
        .catch((error) => {
          alert("Ocorreu um erro, tente novamente. (" + error + ")");
        })
    }
  }

};
</script>
